import assetGame2Architecture from './asset/game2-architecture.png'

import { Button, ButtonGroup, Card, CardHeader, Avatar, Typography, Grid, Container, Divider, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import React, { Component } from 'react';

class Game extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      hint: null,
      tool: null,
      waitingForCloudFormation: null,
    }
    this.launchGame = this.launchGame.bind(this);
    this.restartGame = this.restartGame.bind(this);
    this.showDiagram = this.showDiagram.bind(this);
    this.hinthint1 = this.hinthint1.bind(this);
    this.hinthint2 = this.hinthint2.bind(this);
  }
  
  launchGame(e) {
    e.preventDefault();
    this.setState({waitingForCloudFormation: true});
    window.open(this.props.session.cloudformationCreateUrl, '_blank');
  }
  
  restartGame(e) {
    e.preventDefault();
    localStorage.removeItem('session');
    window.location.reload();
  }
  
  setTool(tool) {
    if (this.state.tool !== tool) {
      this.setState({tool, hint: null});
    } else {
      this.setState({tool: null});
    }
  }
  
  showDiagram(e) {
    e.preventDefault();
    this.setTool('diagram');
  }
  
  setHint(hint) {
    if (this.state.hint !== hint) {
      this.setState({hint, tool: null});
    } else {
      this.setState({hint: null});
    }
  }
  
  hinthint1(e) {
    e.preventDefault();
    this.setHint('hint1');
  }
  
  hinthint2(e) {
    e.preventDefault();
    this.setHint('hint2');
  }
  
  render() {
    if (this.props.session.state === 'connecting') {
      let waitStack = <React.Fragment/>;
      let createStack = <React.Fragment/>;
      if (this.state.waitingForCloudFormation) {
        waitStack = <React.Fragment>
          <Grid item xs={12}>
          <CircularProgress />
          <Typography variant="body1">Waiting for CloudFormation stack to reach status CREATE_COMPLETE.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Button variant="contained" onClick={this.restartGame}>Cancel</Button>
          </Grid>
        </React.Fragment>
      } else {
        createStack = <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={this.launchGame}>Create CloudFormation Stack</Button>
        <Button variant="contained" onClick={this.restartGame} style={{marginLeft:"10px"}}>Cancel</Button>
        </Grid>
      }
      return (
        <Container fixed maxWidth="xl">
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Typography variant="h1">Game #2</Typography>
        <Typography variant="h2">Access denied, fixing IAM policies</Typography>
        <Divider/>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">
        First, deploy the broken infrastructure to one of your AWS accounts. Our CloudFormation template will create the following resources:<br/>
        <ul>
          <li>S3 Bucket</li>
          <li>VPC</li>
          <li>Subnet</li>
          <li>Network Access Control List (ACL)</li>
          <li>Internet Gateway</li>
          <li>VPC Endpoint (S3)</li>
          <li>EC2 instance</li>
          <li>Security Group</li>
          <li>Systems Manager Maintenance Window</li>
          <li>IAM role for EC2 instance</li>
          <li>IAM role used by AWS Debug Games to verify results</li>
        </ul>
        <img src={assetGame2Architecture} style={{maxWidth: '100%'}} alt="Architecture"/>
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Alert severity="info">Estimated AWS infrastructure costs per hour: ~$0.01</Alert>
        </Grid>
        <Grid item xs={12}>
        <Alert severity="warning">By creating the CloudFormation stack you create an IAM role that grants the AWS Debug Games limited read access to your AWS account. Therefore, use an AWS account without any critical workloads and sensitive data.</Alert>
        </Grid>
        {waitStack}
        {createStack}
        <Grid item xs={12}>
        <Divider/>
        <Typography variant="body1" align="right">
        Made with ❤️ by <a href="https://cloudonaut.io" target="_blank" rel="noreferrer">cloudonaut.io</a>. <a href="mailto:hello@cloudonaut.io" target="_blank" rel="noreferrer">Feedback</a> welcome!
        </Typography>
        </Grid>
        </Grid>
        </Container>
      );
    }
    if (this.props.session.state === 'disconnected') {
      return (
        <React.Fragment>
        <Alert severity="info">CloudFormation Stack deleted</Alert>
        <Button variant="contained" color="primary" onClick={this.restartGame}>Restart</Button>
        </React.Fragment>
      );
    }
    if (this.props.session.state === 'connected') {
      console.log('connected');
      let done = <React.Fragment/>;
      let error = <React.Fragment/>;
      if (this.props.goals.length > 0 && this.props.goals.every(goal => goal.state === 'reached')) {
        done = <Grid item xs={12}>
        <Alert severity="success">
          <AlertTitle>You reached all goals!</AlertTitle>
          Don't forget to delete the <a href={`https://console.aws.amazon.com/cloudformation/home?region=${this.props.session.aws.region}#/stacks/events?stackId=${encodeURIComponent(this.props.session.aws.stackId)}`} target="_blank" rel="noreferrer">CloudFormation stack</a> to avoid unnecessary AWS costs!
        </Alert>
        </Grid>;
      }
      if (this.props.error) {
        error = <Grid item xs={12}>
        <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {this.props.error.message}
        </Alert>
        </Grid>;
      }
      const bugItems = this.props.bugs.filter(bug => bug.state !== 'undiscovered').map(bug => {
        return <Grid item xs={12}>
        <Card key={bug.id}>
        <CardHeader avatar={<Avatar style={{backgroundColor: (bug.state === 'fixed' ? 'green' : 'maroon')}}>{bug.state === 'fixed' ? <CheckIcon/> : <ErrorOutlineIcon/>}</Avatar>} title={bug.title} />
        </Card>
        </Grid>;
      })
      const goalItems = this.props.goals.map(goal => {
        return <Grid item xs={12}>
        <Card key={goal.id}>
        <CardHeader avatar={<Avatar style={{backgroundColor: (goal.state === 'reached' ? 'green' : 'maroon')}}>{goal.state === 'reached' ? <CheckIcon/> : <ErrorOutlineIcon/>}</Avatar>} title={goal.title} /> 
        </Card>
        </Grid>;
      })
      let hint = <React.Fragment/>
      let tool = <React.Fragment/>
      if (this.state.tool === 'diagram') {
        tool = <React.Fragment>
        <Grid item xs={12}>
        <Typography variant="h4">Technique</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">
          The architecture consists of the following components.
        </Typography>
        <ul>
          <li>S3 Bucket</li>
          <li>VPC</li>
          <li>Subnet</li>
          <li>Network Access Control List (ACL)</li>
          <li>Internet Gateway</li>
          <li>VPC Endpoint (S3)</li>
          <li>EC2 instance</li>
          <li>Security Group</li>
          <li>Systems Manager Maintenance Window</li>
          <li>IAM role for EC2 instance</li>
          <li>IAM role used by AWS Debug Games to verify results</li>
        </ul>
        <img src={assetGame2Architecture} style={{maxWidth: '100%'}} alt="Architecture"/>
        </Grid>
        </React.Fragment>;
      }
      if (this.state.hint === 'hint1') {
        hint = <React.Fragment>
        <Grid item xs={12}>
        <Typography variant="h4">Hint: VPC Endpoint</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">A VPC endpoint is used for traffic from the EC2 instance to S3. A <a href="https://docs.aws.amazon.com/vpc/latest/privatelink/vpc-endpoints-access.html" target="_blank" rel="noreferrer">VPC endpoint policy</a> controls access to S3. Right now, the endpoint policy contains two mistakes. The resource identifier for <code>s3:GetObject</code> and <code>s3:PutObject</code> is not correct. See <a href="https://docs.aws.amazon.com/service-authorization/latest/reference/list_amazons3.html#amazons3-resources-for-iam-policies" target="_blank" rel="noreferrer">Resource types defined by Amazon S3</a> to learn more.</Typography>
        </Grid>
        </React.Fragment>;
      } else if (this.state.hint === 'hint2') {
        hint = <React.Fragment>
        <Grid item xs={12}>
        <Typography variant="h4">Hint: IAM Policy</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">The IAM role attached to the EC2 instance contains an inline policy. This policy grants the AWS CLI running on the EC2 instance access to S3. However, as the maintenance window runs a <code>aws s3 sync</code> command the policy needs to grant access to the following actions.</Typography>
        <ul>
          <li><code>s3:ListBucket</code> (source and the target bucket)</li>
          <li><code>s3:GetObject</code> (source bucket)</li>
          <li><code>s3:PutObject</code> (target bucket)</li>
        </ul>
        </Grid>
        </React.Fragment>;
      }
      return (
        <Container fixed maxWidth="xl">
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Typography variant="h1">Game #2</Typography>
        <Typography variant="h2">Access denied, fixing IAM policies</Typography>
        <Divider/>
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={2}>
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="h5">Goals</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">The following goals have to be reached to complete the game. We mark the completed goals with an <CheckIcon/>:</Typography>
        </Grid>
        {goalItems}
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="h5">Tools & Techniques</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">
        The following tools and techniques might help you:
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <ButtonGroup color="primary" orientation="vertical">
        <Button onClick={this.showDiagram}>Architecture diagram</Button>
        </ButtonGroup>
        </Grid>
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="h5">Discovered Bugs</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">While you debug the issue, you discover different bugs. We keep track of them for you in this list and mark them with an <CheckIcon/> if you fixed them:</Typography>
        </Grid>
        {bugItems}
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="h5">Solution hints</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">
        If you are stuck, the following hints might help you:
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <ButtonGroup color="secondary" orientation="vertical">
        <Button onClick={this.hinthint1}>Hint 1</Button>
        <Button onClick={this.hinthint2}>Hint 2</Button>
        </ButtonGroup>
        </Grid>
        </Grid>
        </Grid>
        </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
        <Grid item xs={12} md={8} lg={9} xl={10}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="body1">
        Your mission is to synchronize data between two S3 buckets. An EC2 instance is running. Every minute, the Systems Manager executes task triggered by a maintenance window. The task uses aws s3 sync (AWS CLI) to synchronize data between the source bucket and the desitination bucket. Unfortunately, the task fails with an <code>access denied</code> error.
        </Typography>
        <ul>
        <li>VPC: <a href={`https://console.aws.amazon.com/go/view?arn=arn:aws:ec2:${this.props.session.aws.region}:${this.props.session.aws.accountId}:vpc/${this.props.session.aws.resources.VpcId}`} target="_blank" rel="noreferrer">{this.props.session.aws.resources.VpcId}</a></li>
        <li>EC2 instance: <a href={`https://console.aws.amazon.com/go/view?arn=arn:aws:ec2:${this.props.session.aws.region}:${this.props.session.aws.accountId}:instance/${this.props.session.aws.resources.VirtualMachineId}`} target="_blank" rel="noreferrer">{this.props.session.aws.resources.VirtualMachineId}</a></li>
        <li>SSM Maintenance Window: <a href={`https://console.aws.amazon.com/go/view?arn=arn:aws:ssm:${this.props.session.aws.region}:${this.props.session.aws.accountId}:maintenancewindow/${this.props.session.aws.resources.MaintenanceWindowId}`} target="_blank" rel="noreferrer">{this.props.session.aws.resources.MaintenanceWindowId}</a></li>
        <li>S3 bucket (source): <a href={`https://console.aws.amazon.com/go/view?arn=arn:aws:s3:::cloudonaut-game-${this.props.session.aws.region}`} target="_blank" rel="noreferrer">cloudonaut-game-{this.props.session.aws.region}</a></li>
        <li>S3 bucket (target): <a href={`https://console.aws.amazon.com/go/view?arn:aws:s3:::${this.props.session.aws.resources.BucketName}`} target="_blank" rel="noreferrer">{this.props.session.aws.resources.BucketName}</a></li>
        </ul>
        </Grid>
        <Grid item xs={12}>
        <Alert severity="info">The <strong>Goals</strong> section tells you if you completed the game's goals. If you need inspiration, check out the <strong>Tools & Techniques</strong> box. If you are stuck, we prepared <strong>Solution hints</strong> for you.</Alert>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">Don't forget to delete the <a href={`https://console.aws.amazon.com/cloudformation/home?region=${this.props.session.aws.region}#/stacks/events?stackId=${encodeURIComponent(this.props.session.aws.stackId)}`} target="_blank" rel="noreferrer">CloudFormation stack</a> after playing the game to avoid unnecessary AWS costs! In case something went wrong, <button onClick={this.restartGame}>Reset the game</button>.</Typography>
        </Grid>
        <Grid item xs={12}>
        <Divider />
        </Grid>
        {error}
        {done}
        {tool}
        {hint}
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Divider/>
        <Typography variant="body1" align="right">
        Made with ❤️ by <a href="https://cloudonaut.io" target="_blank" rel="noreferrer">cloudonaut.io</a>. <a href="mailto:hello@cloudonaut.io" target="_blank" rel="noreferrer">Feedback</a> welcome!
        </Typography>
        </Grid>
        </Grid>
        </Container>
      );
    }
    return (<Container></Container>)
  }
}
      
export default Game;
      
import assetGame1Architecture from './asset/game1-architecture.png'
import assetGame1VpcReachabilityAnalyzer from './asset/game1-vpc-reachability-analyzer.png';
import assetGame1VpcAnalysis from './asset/game1-vpc-analysis.png';

import { Button, ButtonGroup, Card, CardHeader, Avatar, Typography, Grid, Container, Divider, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import React, { Component } from 'react';

class Game extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      hint: null,
      tool: null,
      waitingForCloudFormation: null,
    }
    this.launchGame = this.launchGame.bind(this);
    this.restartGame = this.restartGame.bind(this);
    this.showDiagram = this.showDiagram.bind(this);
    this.executeSshCommand = this.executeSshCommand.bind(this);
    this.useReachabilityAnalyzer = this.useReachabilityAnalyzer.bind(this);
    this.hintNacl = this.hintNacl.bind(this);
    this.hintSg = this.hintSg.bind(this);
  }
  
  launchGame(e) {
    e.preventDefault();
    this.setState({waitingForCloudFormation: true});
    window.open(this.props.session.cloudformationCreateUrl, '_blank');
  }

  restartGame(e) {
    e.preventDefault();
    localStorage.removeItem('session');
    window.location.reload();
  }
  
  setTool(tool) {
    if (this.state.tool !== tool) {
      this.setState({tool, hint: null});
    } else {
      this.setState({tool: null});
    }
  }
  
  showDiagram(e) {
    e.preventDefault();
    this.setTool('diagram');
  }
  
  executeSshCommand(e) {
    e.preventDefault();
    this.setTool('ssh');
  }
  
  useReachabilityAnalyzer(e) {
    e.preventDefault();
    this.setTool('reachability-analyzer');
  }
  
  setHint(hint) {
    if (this.state.hint !== hint) {
      this.setState({hint, tool: null});
    } else {
      this.setState({hint: null});
    }
  }
  
  hintNacl(e) {
    e.preventDefault();
    this.setHint('nacl');
  }
  
  hintSg(e) {
    e.preventDefault();
    this.setHint('sg');
  }
  
  render() {
    if (this.props.session.state === 'connecting') {
      let waitStack = <React.Fragment/>;
      let createStack = <React.Fragment/>;
      if (this.state.waitingForCloudFormation) {
        waitStack = <React.Fragment>
          <Grid item xs={12}>
          <CircularProgress />
          <Typography variant="body1">Waiting for CloudFormation stack to reach status CREATE_COMPLETE.</Typography>
          </Grid>
          <Grid item xs={12}>
          <Button variant="contained" onClick={this.restartGame}>Cancel</Button>
          </Grid>
        </React.Fragment>
      } else {
        createStack = <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={this.launchGame}>Create CloudFormation Stack</Button>
        <Button variant="contained" onClick={this.restartGame} style={{marginLeft:"10px"}}>Cancel</Button>
        </Grid>
      }
      return (
        <Container fixed maxWidth="xl">
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Typography variant="h1">Game #1</Typography>
        <Typography variant="h2">Locked out, debugging SSH connection issues</Typography>
        <Divider/>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">
        To play the game, you have to deploy a CloudFormation stack in your AWS account. The following infrastructure is created:<br/>
        <img src={assetGame1Architecture} style={{maxWidth: '100%'}} alt="Architecture"/>
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Alert severity="info">Estimated AWS infrastructure costs per hour: ~$0.01</Alert>
        </Grid>
        <Grid item xs={12}>
        <Alert severity="warning">By creating the CloudFormation stack you create an IAM role that grants the AWS Debug Games limited read access  to your AWS account. Therefore, use an AWS account without any critical workloads and sensitive data.</Alert>
        </Grid>
        {waitStack}
        {createStack}
        <Grid item xs={12}>
        <Divider/>
        <Typography variant="body1" align="right">
        Made with ❤️ by <a href="https://cloudonaut.io" target="_blank" rel="noreferrer">cloudonaut.io</a>. <a href="mailto:hello@cloudonaut.io" target="_blank" rel="noreferrer">Feedback</a> welcome!
        </Typography>
        </Grid>
        </Grid>
        </Container>
      );
    }
    if (this.props.session.state === 'disconnected') {
      return (
        <React.Fragment>
        <Alert severity="info">CloudFormation Stack deleted</Alert>
        <Button variant="contained" color="primary" onClick={this.restartGame}>Restart</Button>
        </React.Fragment>
      );
    }
    if (this.props.session.state === 'connected') {
      let done = <React.Fragment/>;
      let error = <React.Fragment/>;
      if (this.props.goals.length > 0 && this.props.goals.every(goal => goal.state === 'reached')) {
        done = <Grid item xs={12}>
        <Alert severity="success">
        <AlertTitle>You reached all goals!</AlertTitle>
        Don't forget to delete the <a href={`https://console.aws.amazon.com/cloudformation/home?region=${this.props.session.aws.region}#/stacks/events?stackId=${encodeURIComponent(this.props.session.aws.stackId)}`} target="_blank" rel="noreferrer">CloudFormation stack</a> to avoid unnecessary AWS costs!
        </Alert>
        </Grid>;
      }
      if (this.props.error) {
        error = <Grid item xs={12}>
        <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {this.props.error.message}
        </Alert>
        </Grid>;
      }
      const bugItems = this.props.bugs.filter(bug => bug.state !== 'undiscovered').map(bug => {
        return <Grid item xs={12}>
        <Card key={bug.id}>
        <CardHeader avatar={<Avatar style={{backgroundColor: (bug.state === 'fixed' ? 'green' : 'maroon')}}>{bug.state === 'fixed' ? <CheckIcon/> : <ErrorOutlineIcon/>}</Avatar>} title={bug.title} />
        </Card>
        </Grid>;
      })
      const goalItems = this.props.goals.map(goal => {
        return <Grid item xs={12}>
        <Card key={goal.id}>
        <CardHeader avatar={<Avatar style={{backgroundColor: (goal.state === 'reached' ? 'green' : 'maroon')}}>{goal.state === 'reached' ? <CheckIcon/> : <ErrorOutlineIcon/>}</Avatar>} title={goal.title} /> 
        </Card>
        </Grid>;
      })
      let hint = <React.Fragment/>
      let tool = <React.Fragment/>
      if (this.state.tool === 'diagram') {
        tool = <React.Fragment>
        <Grid item xs={12}>
        <Typography variant="h4">Technique</Typography>
        </Grid>
        <Grid item xs={12}>
        <img src={assetGame1Architecture} style={{maxWidth: '100%'}} alt="Architecture"/>
        </Grid>
        </React.Fragment>;
      } else if (this.state.tool === 'ssh') {
        tool = <React.Fragment>
        <Grid item xs={12}>
        <Typography variant="h4">Technique</Typography>
        </Grid>
        <Grid item xs={12}>
        <ol>
        <li>Execute <pre>ssh ec2-user@{this.props.session.aws.resources.VirtualMachinePublicIp}</pre></li>
        <li>The command seems to run forever with no result. Press CTRL+C to kill it.</li>
        <li>Can you ssh into a known good host to ensure that your setup works?</li>
        <li>If yes, you know that the problem is not on the client side!</li>
        </ol>
        </Grid>
        </React.Fragment>;
      } else if (this.state.tool === 'reachability-analyzer') {
        tool = <React.Fragment>
        <Grid item xs={12}>
        <Typography variant="h4">Tool</Typography>
        </Grid>
        <Grid item xs={12}>
        <Alert severity="info">AWS charges $0.10 for each VPC Reachability Analyzer analysis.</Alert>
        <ol>
        <li>Open <a href={`https://console.aws.amazon.com/vpc/home?region=${this.props.session.aws.region}#ReachabilityAnalyzer:`} target="_blank" rel="noreferrer">VPC Reachability Analyzer</a></li>
        <li>Click <strong>Create and analyze path</strong></li>
        <li>Simulate TCP traffic coming from the Internet (IGW {this.props.session.aws.resources.InternetGatewayId} from your <a href="http://checkip.amazonaws.com/" target="_blank" rel="noreferrer">IP address</a>) to the EC2 instance {this.props.session.aws.resources.VirtualMachineId} on <strong>port 22</strong><br/><img src={assetGame1VpcReachabilityAnalyzer} style={{maxWidth: '100%'}} alt="Create analyze path"/></li>
        <li>Create the path and wait for the first analysis to complete (state Succeeded)</li>
        <li>Have a look at the generated <strong>Explanations</strong><br/><img src={assetGame1VpcAnalysis} style={{maxWidth: '100%'}} alt="Two bug are discoveres"/></li>
        </ol>
        </Grid>
        </React.Fragment>;
      }
      if (this.state.hint === 'nacl') {
        hint = <React.Fragment>
        <Grid item xs={12}>
        <Typography variant="h4">Hint: NACL</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">The Network ACL blocks all outgoing traffic. It misses an allow rule for <a href="https://docs.aws.amazon.com/vpc/latest/userguide/vpc-network-acls.html#nacl-ephemeral-ports" target="_blank" rel="noreferrer">ephemeral ports</a>! Ephemeral ports are allocated automatically from a predefined range by the operating system. An ephemeral port is typically used by TCP and UDP as the port assignment for the client end of a client-server communication to a particular port (usually a well-known port) on a server. The used ephemeral port range depends on the operating system. Usually, ports 1024-65535 are allowed to support all kinds of workloads.</Typography>
        </Grid>
        </React.Fragment>;
      } else if (this.state.hint === 'sg') {
        hint = <React.Fragment>
        <Grid item xs={12}>
        <Typography variant="h4">Hint: Security Group</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">The security group does not allow inbound connections on port 22 (TCP). To make the bug check pass, please allow traffic from 0.0.0.0/0 and not only your IP address!</Typography>
        </Grid>
        </React.Fragment>;
      }
      return (
        <Container fixed maxWidth="xl">
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Typography variant="h1">Game #1</Typography>
        <Typography variant="h2">Locked out, debugging SSH connection issues</Typography>
        <Divider/>
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={2}>
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="h5">Goals</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">The following goals have to be reached to complete the game. We mark the completed goals with an <CheckIcon/>:</Typography>
        </Grid>
        {goalItems}
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="h5">Tools & Techniques</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">
        The following tools and techniques might help you:
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <ButtonGroup color="primary" orientation="vertical">
        <Button onClick={this.showDiagram}>Architecture diagram</Button>
        <Button onClick={this.executeSshCommand}>Execute SSH command</Button>
        <Button onClick={this.useReachabilityAnalyzer}>Run Reachability Analyzer</Button>
        </ButtonGroup>
        </Grid>
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="h5">Discovered Bugs</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">While you debug the issue, you discover different bugs. We keep track of them for you in this list and mark them with an <CheckIcon/> if you fixed them:</Typography>
        </Grid>
        {bugItems}
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="h5">Solution hints</Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1">
        If you are stuck, the following hints might help you:
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <ButtonGroup color="secondary" orientation="vertical">
        <Button onClick={this.hintNacl}>Hint 1</Button>
        <Button onClick={this.hintSg}>Hint 2</Button>
        </ButtonGroup>
        </Grid>
        </Grid>
        </Grid>
        </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
        <Grid item xs={12} md={8} lg={9} xl={10}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <Typography variant="body1">
        An EC2 instance is running, but you receive no response on port 22 (SSH). Investigate the issue and fix the bugs! You can use any debugging tool and technique that you can think of. The following links might help you to jump into the AWS Management Console.
        </Typography>
        <ul>
        <li>VPC <a href={`https://console.aws.amazon.com/vpc/home?region=${this.props.session.aws.region}#VpcDetails:VpcId=${this.props.session.aws.resources.VpcId}`} target="_blank" rel="noreferrer">{this.props.session.aws.resources.VpcId}</a></li>
        <li>Subnet <a href={`https://console.aws.amazon.com/vpc/home?region=${this.props.session.aws.region}#SubnetDetails:subnetId=${this.props.session.aws.resources.SubnetAPublicId}`} target="_blank" rel="noreferrer">{this.props.session.aws.resources.SubnetAPublicId}</a></li>
        <li>EC2 instance <a href={`https://console.aws.amazon.com/ec2/v2/home?region=${this.props.session.aws.region}#InstanceDetails:instanceId=${this.props.session.aws.resources.VirtualMachineId}`} target="_blank" rel="noreferrer">{this.props.session.aws.resources.VirtualMachineId}</a></li>
        </ul>
        <Typography variant="body1">
        The <strong>Goals</strong> section tells you if you completed the game's goals. If you need inspiration, check out the <strong>Tools & Techniques</strong> box. If you are stuck, we prepared <strong>Solution hints</strong> for you.
        </Typography>
        <Typography variant="body1">Don't forget to delete the <a href={`https://console.aws.amazon.com/cloudformation/home?region=${this.props.session.aws.region}#/stacks/events?stackId=${encodeURIComponent(this.props.session.aws.stackId)}`} target="_blank" rel="noreferrer">CloudFormation stack</a> after playing the game to avoid unnecessary AWS costs! In case something went wrong, <button onClick={this.restartGame}>Reset the game</button>.</Typography>
        </Grid>
        <Grid item xs={12}>
        <Divider />
        </Grid>
        {error}
        {done}
        {tool}
        {hint}
        </Grid>
        </Grid>
        <Grid item xs={12}>
        <Divider/>
        <Typography variant="body1" align="right">
        Made with ❤️ by <a href="https://cloudonaut.io" target="_blank" rel="noreferrer">cloudonaut.io</a>. <a href="mailto:hello@cloudonaut.io" target="_blank" rel="noreferrer">Feedback</a> welcome!
        </Typography>
        </Grid>
        </Grid>
        </Container>
      );
    }
    return (<Container></Container>)
  }
}
      
export default Game;
      
import React, { Component } from 'react';
import { Button, CircularProgress, Typography, Grid, Container, Card, CardContent, CardActions, List, ListItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Game1 from './Game1.js'
import Game2 from './Game2.js'


const API_ENDPOINT = 'https://wh6pvtc7ej.execute-api.eu-west-1.amazonaws.com';

function fetchResponseHandler(response) {
  if (response.ok) {
    return response.json();
  } else {
    if (response.status === 401 || response.status === 403) {
      console.log('Redirecting', `${API_ENDPOINT}/cognito/login`);
      window.location = `${API_ENDPOINT}/cognito/login`;
    }
    throw new Error(`Unexpected status ${response.status} for ${response.url}`);
  }
}

function authFetch(uri, options) {
  let o = Object.assign({}, options, {headers: {Authorization: localStorage.getItem('id_token')}});
  return fetch(uri, o);
}


class App extends Component {

  

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      profile: {
        credits: 0,
        referal_key: ''
      },
      session: {
        state: 'uninitialized'
      },
      //session: {
      //  state: 'connecting',
      //  id: '55b9263a-c5ea-472f-9ce9-7d30d5e30996'
      //},
      bugs: [],
      goals: [],
      error: null,
      home: 'welcome',
      creditDialogOpen: false
    };
    const sessionId = localStorage.getItem('session');
    if (sessionId) {
      this.state.session = {
        state: 'loading',
        id: sessionId
      }
      this.startSession = this.startSession.bind(this);
    }
    if (localStorage.getItem('id_token')) {
      this.state.home = 'start';
    }
    this.start = this.start.bind(this);
    this.openCreditDialog = this.openCreditDialog.bind(this);
    this.closeCreditDialog = this.closeCreditDialog.bind(this);
  }

  async start(e) {
    e.preventDefault();
    if (!localStorage.getItem('id_token')) {
      window.location = `${API_ENDPOINT}/cognito/login`;
    } else {
      this.setState({home: 'start'});
    }
  }

  async openCreditDialog(e) {
    e.preventDefault();
    const profileData = await authFetch(`${API_ENDPOINT}/user/profile`).then(fetchResponseHandler);
    this.setState({
      profile: {
        credits: profileData.credits,
        referalKey: profileData.referal_key
      } 
    });
    this.setState({creditDialogOpen: true});
  }

  async closeCreditDialog(e) {
    e.preventDefault();
    this.setState({creditDialogOpen: false});
  }

  async startSession(gameId, e) {
    e.preventDefault();
    
    this.setState({loading: true});
    const req = {
      gameId: gameId
    };
    if (localStorage.getItem('referal_key')) {
      req.referal_key = localStorage.getItem('referal_key');
    }
    const data = await authFetch(`${API_ENDPOINT}/session`, {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(req)}).then(fetchResponseHandler);
    if (data.error) {
      if (data.error === 'INSUFFICIENT_CREDITS') {
        this.setState({loading: false});
        this.openCreditDialog(e);
      } else {
        this.setState({
          error: data,
          loading: false
        });
      }
    } else {
      localStorage.setItem('session', data.sessionId);
      this.setState({
        session: {
          state: 'connecting',
          id: data.sessionId,
          cloudformationCreateUrl: data.cloudformationCreateUrl,
          gameId: gameId
        },
        loading: false
      });
    }
  }

  async refresh() {
    try {
      if (this.state.home === 'start') {
        const profileData = await authFetch(`${API_ENDPOINT}/user/profile`).then(fetchResponseHandler);
        this.setState({
          profile: {
            credits: profileData.credits,
            referalKey: profileData.referal_key
          } 
        });
      }
      if (this.state.session.state === 'loading') {
        const data = await authFetch(`${API_ENDPOINT}/session/${this.state.session.id}`)
          .then(fetchResponseHandler);
        this.setState({
          session: {
            ...this.state.session,
            state: data.session.state,
            aws: data.session.aws,
            gameId: data.session.gameId,
            cloudformationCreateUrl: data.session.cloudformationCreateUrl,
          }
        });
      }
      if (this.state.session.state === 'connecting') {
        const data = await authFetch(`${API_ENDPOINT}/session/${this.state.session.id}`)
          .then(fetchResponseHandler);
        this.setState({
          session: {
            ...this.state.session,
            state: data.session.state,
            aws: data.session.aws,
            gameId: data.session.gameId,
            cloudformationCreateUrl: data.session.cloudformationCreateUrl,
          }
        });
      }
      if (this.state.session.state === 'connected') {
        const sessionData = await authFetch(`${API_ENDPOINT}/session/${this.state.session.id}`)
          .then(fetchResponseHandler);
        const bugData = await authFetch(`${API_ENDPOINT}/session/${this.state.session.id}/bug`)
          .then(fetchResponseHandler);
        const goalData = await authFetch(`${API_ENDPOINT}/session/${this.state.session.id}/goal`)
          .then(fetchResponseHandler);
        this.setState({
          session: {
            ...this.state.session,
            state: sessionData.session.state,
            gameId: sessionData.session.gameId,
            cloudformationCreateUrl: sessionData.session.cloudformationCreateUrl,
          },
          bugs: bugData.bugs,
          goals: goalData.goals
        });
      }
    } catch (err) {
      this.setState({
        error: err
      });
    }
    console.log(this.state);
  }

  async componentDidMount() {
    const intervalId = setInterval(this.refresh.bind(this), 5000);
    this.setState({intervalId});
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.code) {
      const data = await fetch(`${API_ENDPOINT}/cognito/code/${params.code}`).then(fetchResponseHandler);
      localStorage.setItem('id_token', data.id_token);
      window.location = `/`;
    }
    if (params.referal_key) {
      localStorage.setItem('referal_key', params.referal_key);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    if (this.state.loading) {
      return (<CircularProgress />);
    }
    if (this.state.session.state === 'loading') {
      return (<CircularProgress />);
    }
    if (this.state.session.state === 'uninitialized') {
      const twitterParams = new URLSearchParams();
      twitterParams.append('url', `https://games.cloudonaut.io?referal_key=${this.state.profile.referalKey}`);
      twitterParams.append('text', 'How talented are you at solving problems? Put your skills to the test. Join the AWS Debug Games. I enjoy the challenges and have already learned a lot. #awscommunity');
      const twitterUrl = `https://twitter.com/share?${twitterParams.toString()}`;

      const mailParams = new URLSearchParams();
      mailParams.append('subject', 'Join the AWS Debug Games');
      mailParams.append('body', `How talented are you at solving problems? Put your skills to the test. Join the AWS Debug Games. I enjoy the challenges and have already learned a lot. https://games.cloudonaut.io/?referal_key=${this.state.profile.referalKey}`);
      const mailUrl = `mailto:?${mailParams.toString()}`;

      let error = <React.Fragment/>;
      if (this.state.error) {
        error = <Grid item xs={12}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {this.state.error.message}
          </Alert>
        </Grid>;
      }
      let referal = <React.Fragment/>;
      if (this.state.profile.referalKey) {
        referal = <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h3">Need more credits?</Typography>
            <Typography variant="body1">
              Starting a game requires a credit. Your credit balance is {this.state.profile.credits}. To get more credits, you need to invite others to play AWS Debug Games. We reward you with 5 credits for every newly registered user.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="body1" component="p">
                  How talented are you at solving problems? Put your skills to the test. Join the AWS Debug Games. I enjoy the challenges and have already learned a lot. https://games.cloudonaut.io/?referal_key={this.state.profile.referalKey} #awscommunity
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href={twitterUrl}>Share via Twitter</Button>
                <Button size="small" href={mailUrl}>Share via E-Mail</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>;
      }
      let welcome = <Container fixed maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h1">AWS Debug Games</Typography>
            <Typography variant="h2">Prove your AWS expertise by solving tricky challenges.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Our games challenge you anew every time. Every game starts with deploying a broken cloud infrastructure into your AWS account. It's your job to uncover and fix all the problems. If necessary, we will give you tips on how to improve your debugging skills.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3">
              What you get
            </Typography>
            <List>
              <ListItem>Learn how to solve common AWS issues</ListItem>
              <ListItem>Prove your AWS skills</ListItem>
              <ListItem>Have fun solving the riddles</ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3">
              What you need
            </Typography>
            <List>
              <ListItem>Basic AWS knowledge</ListItem>
              <ListItem>~30 minutes per game</ListItem>
              <ListItem>Access to an AWS account</ListItem>
              <ListItem>Some bucks to spend on AWS infrastructure costs (less than $1 USD per game)</ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" size="large" onClick={async (e) => {await this.start(e);}}>Get started!</Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="right">
               Made with ❤️ by <a href="https://cloudonaut.io" target="_blank" rel="noreferrer">cloudonaut.io</a>. <a href="mailto:hello@cloudonaut.io" target="_blank" rel="noreferrer">Feedback</a> welcome!
            </Typography>
          </Grid>
        </Grid>
        
      </Container>;

      let creditDialog = <Dialog open={this.state.creditDialogOpen} onClose={this.closeCreditDialog}>
        <DialogTitle>Not enough credits</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Starting a game requires a credit. Your credit balance is {this.state.profile.credits}. To get more credits, you need to invite others to play AWS Debug Games. We reward you with 5 credits for every newly registered user. Invite others by using the URL https://games.cloudonaut.io/?referal_key={this.state.profile.referalKey} or the following predefined message.
          </DialogContentText>
          <Card variant="outlined">
              <CardContent>
                <Typography variant="body1" component="p">
                  How talented are you at solving problems? Put your skills to the test. Join the AWS Debug Games. I enjoy the challenges and have already learned a lot. https://games.cloudonaut.io/?referal_key={this.state.profile.referalKey} #awscommunity
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href={twitterUrl}>Share via Twitter</Button>
                <Button size="small" href={mailUrl}>Share via E-Mail</Button>
              </CardActions>
            </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeCreditDialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      
      let start = <Container fixed maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h1">AWS Debug Games</Typography>
            <Typography variant="h2">Prove your AWS expertise by solving tricky challenges.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
            Our games challenge you anew every time. Every game starts with deploying a broken cloud infrastructure into your AWS account. It's your job to uncover and fix all the problems. If necessary, we will give you tips on how to improve your debugging skills.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {error}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              Game #1: Locked out, debugging SSH connection issues
            </Typography>
            <Typography variant="body1">
              Establishing an SSH connection to an EC2 instance fails. Check the <strong>networking and firewall</strong> configurations to fix the issue. Hurry up, time is short!
            </Typography>
            <Button variant="contained" color="primary" onClick={async (e) => {await this.startSession('game1', e);}}>Start game</Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">
              Game #2: Access denied, fixing IAM policies
            </Typography>
            <Typography variant="body1">
              Synchronizing data between two S3 buckets does not work. Check the <strong>permissions to access S3</strong>. Be warned, access policies are tricky.
            </Typography>
            <Button variant="contained" color="primary" onClick={async (e) => {await this.startSession('game2', e);}}>Start game</Button>
          </Grid>
          {creditDialog}
          {referal}
          <Grid item xs={12}>
            <Typography variant="body1" align="right">
               Made with ❤️ by <a href="https://cloudonaut.io" target="_blank" rel="noreferrer">cloudonaut.io</a>. <a href="mailto:hello@cloudonaut.io" target="_blank" rel="noreferrer">Feedback</a> welcome!
            </Typography>
          </Grid>
        </Grid>
      </Container>;

      if (this.state.home === 'welcome') {
        return welcome;
      } else {
        return start;
      }
    }
    if (this.state.session.gameId === 'game1') {
      return (<Game1 session={this.state.session} goals={this.state.goals} bugs={this.state.bugs} error={this.state.error}></Game1>);
    }
    if (this.state.session.gameId === 'game2') {
      return (<Game2 session={this.state.session} goals={this.state.goals} bugs={this.state.bugs} error={this.state.error}></Game2>);
    } 
  }
}

export default App;
